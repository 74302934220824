export const GA4_EVENTS = {
  PAGEVIEW: 'page_view',
  PAGEVIEW_ROUNDTRIP: 'dl_view_item',
  PAGEVIEW_BUILDINGBLOCK: 'dl_view_item',
  PAGEVIEW_ERROR: 'page_error',
  SERVER_ERROR: 'server_error',
  ADD_TO_CART: 'dl_add_to_cart',
  REMOVE_FROM_CART: 'dl_remove_from_cart',
  VIEW_CART: 'dl_view_cart',
  REQUEST_FORM_OPEN: 'dl_begin_request_form',
  REQUEST_FORM_PAGINATION: 'dl_request_form',
  REQUEST_FORM_SUBMIT: 'dl_request_form_complete',
  BOOKING_FORM_OPEN: 'dl_begin_booking_form',
  BOOKING_FORM_PAGINATION: 'dl_booking_form',
  BOOKING_FORM_SUBMIT: 'dl_booking_form_complete',
  ENQUIRY_FORM_OPEN: 'dl_enquiry_modal_opened',
  ENQUIRY_FORM_SUBMIT: 'dl_enquiry_modal_message_sent',
}

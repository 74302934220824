'use client'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import type { CartContextProps, CartItemProps } from '@typings'
import { RaButton, RaRichText, RaTypography } from '@components/core/index.core'
import { LOCAL_STORAGE } from '@constants/local-storage.constants'
import { SETTINGS } from '@constants/settings.constants'
import { useUi } from '@contexts/ui.context'
import { GA4Events } from '@utilities/ga4-helpers/GA4Events.helpers'
import { capitalize, translate } from '@utilities/helpers/core.helpers'
import { getFromLocalStorage } from '@utilities/helpers/local-storage.helpers'
import { removeFromLocalStorage } from '@utilities/helpers/local-storage.helpers'
import { setLocalStorage } from '@utilities/helpers/local-storage.helpers'

const _default = null

export const CartContext = createContext<CartContextProps>(null)
CartContext.displayName = 'CartContext'

const getLabel = (input: string) => capitalize(translate(input))

export const CartContextProvider = ({
  GTM_ID,
  destination,
  dialogLabels,
  children,
}) => {
  const ga4Events = new GA4Events({ mode: 'client', gtmId: GTM_ID })
  const [cartItems, setCartItems] = useState<null | CartItemProps[]>(_default)
  const [enableCart, setEnableCart] = useState(false)
  const ui = useUi()

  const cartEventModals = {
    roundtripAlreadyInCart: (
      <div>
        <RaTypography>{getLabel('ROUNDTRIP_ALREADY_SELECTED')}</RaTypography>
        <RaButton
          label={translate('ROUNDTRIP_ALREADY_SELECTED_DIALOG_BUTTON')}
          onClick={ui.closeModal}
        />
      </div>
    ),
    buildingblockAlreadyInCart: (
      <div>
        <RaTypography>
          {getLabel('BUILDING_BLOCK_ALREADY_IN_CART')}
        </RaTypography>
        <RaButton
          label={translate('ROUNDTRIP_ALREADY_SELECTED_DIALOG_BUTTON')}
          onClick={ui.closeModal}
        />
      </div>
    ),
    addedRoundtrip: (
      <div>
        <RaRichText content={dialogLabels?.rt?.text} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          <RaButton
            fullWidth
            variant="primary"
            link={dialogLabels?.rt?.link}
          />
          <RaButton
            fullWidth
            label={dialogLabels?.rt?.close_button_text}
            onClick={ui.closeModal}
            variant="secondary-dark"
          />
        </div>
      </div>
    ),
    addedBuildingblock: (
      <div>
        <RaRichText content={dialogLabels?.bb?.text} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          <RaButton
            fullWidth
            variant="primary"
            link={dialogLabels?.bb?.link}
          />
          <RaButton
            fullWidth
            label={dialogLabels?.bb?.close_button_text}
            variant="secondary-dark"
            onClick={ui.closeModal}
          />
        </div>
      </div>
    ),
  }

  // Methods
  const addToCart = (newItems: CartItemProps[]) => {
    const newItemIsRoundtrip = newItems.find(({ type }) => type === 'rundreise')
    if (cartItems) {
      // Users can only add 1 roundtrip max, but they can add multiple building blocks
      const roundtripInCart = cartItems.find(({ type }) => type === 'rundreise')

      if (roundtripInCart && newItemIsRoundtrip) {
        ui.showModal({
          title: capitalize(getLabel('ROUNDTRIPS')),
          content: cartEventModals.roundtripAlreadyInCart,
        })
        return
      }

      // Check if cart already contains this item
      const alreadyInCart: CartItemProps[] = []
      newItems.forEach(newItem => {
        const match = cartItems.find(
          ({ cartId }) =>
            cartId === newItem.cartId && newItem.type !== 'additional_service'
        )
        if (match) alreadyInCart.push(match)
      })

      if (alreadyInCart?.length) {
        ui.showModal({
          title: capitalize(getLabel('BUILDING_BLOCKS')),
          content: cartEventModals.buildingblockAlreadyInCart,
        })
        return
      }
    }

    ga4Events.addToCart(newItems)

    const isRoundtrip = newItemIsRoundtrip?.type === 'rundreise'

    if (isRoundtrip) {
      ui.showModal({
        title: dialogLabels?.rt?.title,
        content: cartEventModals.addedRoundtrip,
        maxWidth: 400,
      })
    } else {
      ui.showModal({
        title: dialogLabels?.bb?.title,
        content: cartEventModals.addedBuildingblock,
        maxWidth: 400,
      })
    }

    if (!cartItems || cartItems.length === 0) {
      setCartItems(newItems)
      setLocalStorage(`${destination}_${LOCAL_STORAGE.CART_ITEMS}`, newItems)
    } else {
      setCartItems(() => [...cartItems, ...newItems])
      setLocalStorage(`${destination}_${LOCAL_STORAGE.CART_ITEMS}`, [
        ...cartItems,
        ...newItems,
      ])
    }
  }

  const removeFromCart = (itemToRemove: CartItemProps) => {
    if (!cartItems) {
      console.warn('cannot remove items from empty cart')
      return
    }

    const itemRemoved = cartItems
      .filter(({ cartId }) => cartId !== itemToRemove.cartId)
      .filter(
        ({ parent }) => parent?.toString() !== itemToRemove.cartId.toString()
      )

    if (itemRemoved.length) {
      ga4Events.removeFromCart(itemRemoved)
    } else {
      ga4Events.removeFromCart([])
    }

    setCartItems(itemRemoved)
    setLocalStorage(`${destination}_${LOCAL_STORAGE.CART_ITEMS}`, itemRemoved)
  }

  const clearCart = () => {
    setCartItems(_default)
    removeFromLocalStorage(`${destination}_${LOCAL_STORAGE.CART_ITEMS}`)
  }

  const moveIndex = (direction: 'up' | 'down', selected: CartItemProps) => {
    const y = cartItems.findIndex(item => item.cartId === selected.cartId)
    const list = cartItems
    let x: number
    if (direction === 'up') {
      // If already at the top or only one item in the list, just ignore
      if (y === 0 || cartItems?.length === 1) return null
      x = y - 1
    }
    if (direction === 'down') {
      if (y === cartItems?.length - 1) return null
      x = y + 1
    }

    const itemToMove = list[y]
    list[y] = list[x]
    list[x] = itemToMove

    setCartItems(() => [...list])

    setTimeout(() => {
      setLocalStorage(`${destination}_${LOCAL_STORAGE.CART_ITEMS}`, list)
    }, 100)
  }

  const cartItemsCount = useMemo(() => {
    if (!cartItems) return 0
    return cartItems.length
  }, [cartItems])

  useEffect(() => {
    if (!destination) setEnableCart(false)
    if (destination && !SETTINGS.DISABLE_CART_DOMAINS.includes(destination))
      setEnableCart(true)
    const fromPreviousSession = getFromLocalStorage(
      `${destination}_${LOCAL_STORAGE.CART_ITEMS}`
    )
    if (fromPreviousSession) setCartItems(fromPreviousSession)
  }, [destination])

  return (
    <CartContext.Provider
      value={{
        cartItems,
        enableCart,
        cartItemsCount,
        addToCart,
        removeFromCart,
        clearCart,
        moveIndex,
      }}>
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => {
  return useContext(CartContext)
}

import { memo, useCallback, useMemo } from 'react'
import { RaIcon, RaTornEdge, RaTypography } from '@components/core/index.core'
import { Loop, stripFrontendUrl } from '@utilities/helpers/core.helpers'
import { ICONS } from '@constants/icons.constants'
import clsx from 'clsx'
import Link from 'next/link'

import { ThemeMenu } from '@typings'

import styles from './ra-navbar-dropdown.module.scss'

interface RaNavbarDropdownProps {
  menu: ThemeMenu
  removeTopPadding?: boolean
  disablePrefetch?: boolean
  isOpen?: boolean
}

interface MenuSection {
  link: {
    title: string
    url: string
  }
  subitems: any
  break: boolean
}

export const RaNavbarDropdown = memo(
  ({
    menu,
    removeTopPadding = false,
    disablePrefetch,
  }: RaNavbarDropdownProps) => {
    const generateColumns = (items = []) => {
      let columns = []
      for (const col of items) {
        if (col.break) {
          columns[columns.length] = [col]
        } else if (!columns.length) {
          columns = [[col]]
        } else {
          columns[columns.length - 1].push(col)
        }
      }
      return columns
    }
    const columns = generateColumns(menu.subitems)

    const renderSubMenuItems = useCallback(
      (subitems: ThemeMenu['subitems']) => {
        if (!subitems) return null

        return Loop(subitems, (subMenu, n: number) => {
          if (typeof subMenu !== 'object') return null
          if (!subMenu.link?.title) return null

          return (
            <li
              key={n}
              className={styles['nav-dropdown__content__list__item']}>
              <Link
                href={stripFrontendUrl(subMenu.link?.url)}
                title={subMenu.link?.title}
                target={subMenu.link?.target}
                prefetch={!disablePrefetch && subMenu.link.target !== '_blank'}>
                <RaTypography
                  component="span"
                  size="s">
                  {subMenu.link.title}
                </RaTypography>
                <RaIcon icon={ICONS.LINK_ARROW} />
              </Link>
            </li>
          )
        })
      },
      []
    )

    const menuColumns = useMemo(() => {
      if (!menu?.subitems) return []

      const section = Loop(columns, (column: MenuSection[], n: number) => {
        if (typeof column !== 'object') return null

        return (
          <div
            key={n}
            className={styles['nav-dropdown__content__columns']}>
            {Loop(column, (menu: MenuSection, n: number) => {
              if (!menu?.link) return null
              return (
                <div
                  key={n}
                  className={styles['nav-dropdown__content__column']}>
                  {menu.link.url !== '#' ? (
                    <Link
                      title={menu.link.title}
                      target="_blank"
                      href={stripFrontendUrl(menu.link.url)}
                      prefetch={false}>
                      <RaTypography
                        component="span"
                        fontFamily="heading"
                        size="l"
                        color="primary">
                        {menu.link.title}
                      </RaTypography>
                    </Link>
                  ) : (
                    <RaTypography
                      component="span"
                      fontFamily="heading"
                      size="l"
                      color="primary">
                      {menu.link.title}
                    </RaTypography>
                  )}

                  {menu?.subitems?.length > 0 && (
                    <ul className={styles['nav-dropdown__content__list']}>
                      {renderSubMenuItems(menu.subitems)}
                    </ul>
                  )}
                </div>
              )
            })}
          </div>
        )
      })

      return section
    }, [menu.subitems, renderSubMenuItems])

    return (
      <div
        data-name="dropdown-menu"
        className={styles['nav-dropdown']}
        style={
          {
            '--navbar-columns':
              (menuColumns?.length < 4 ? 4 : menuColumns.length) || 5,
          } as React.CSSProperties
        }>
        <div className={styles['nav-dropdown__backdrop']}>
          <nav
            className={clsx(
              styles['nav-dropdown__content'],
              removeTopPadding && styles['nav-dropdown__content--no-padding']
            )}>
            {menuColumns}
          </nav>
        </div>
        <RaTornEdge />
      </div>
    )
  }
)

RaNavbarDropdown.displayName = 'RaNavbarDropdown'

'use client'
import { memo, useMemo, useState } from 'react'
import ErrorBoundary from '@error-boundary'
import dynamic from 'next/dynamic'

import { RaBadge, RaButton, RaIcon } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import { useCart } from '@contexts/cart.context'
import { useSettings } from '@contexts/settings.context'
import { stripWpUrl, translate } from '@utilities/helpers/core.helpers'

import styles from './ra-button-travelplan.module.scss'

const RaShoppingCart = dynamic(() =>
  import('@components/features/RaShoppingCart/RaShoppingCart').then(
    mod => mod.RaShoppingCart
  )
)

interface RaButtonTravelplanProps {
  hasNavbar?: boolean
  isMobile?: boolean
}

export const RaButtonTravelplan = memo(
  ({ hasNavbar, isMobile }: RaButtonTravelplanProps) => {
    const cart = useCart()
    const { themeSettings } = useSettings()
    const [showCartDetails, setShowCartDetails] = useState(false)

    const label = translate('MY_TRAVELPLAN')

    const travelplanUrl = stripWpUrl(themeSettings.travelplan_page?.url) || ''

    const openShoppingCart = () => {
      setShowCartDetails(true)
    }

    const closeShoppingCart = () => {
      setShowCartDetails(false)
    }

    const toggleShoppingCart = () => {
      setShowCartDetails(!showCartDetails)
    }

    const suitcaseIcon = useMemo(() => {
      return (
        <div style={{ position: 'relative' }}>
          <RaIcon
            icon={ICONS.SUITCASE_CONTAINED}
            color={isMobile && hasNavbar ? 'dark' : 'light'}
          />
          <RaBadge
            withBorder
            badge={cart?.cartItemsCount}
          />
        </div>
      )
    }, [cart?.cartItemsCount, hasNavbar, isMobile])

    if (isMobile) {
      return (
        <div className={styles['ra-button-travelplan']}>
          <RaButton
            variant="icon"
            icon={suitcaseIcon}
            onClick={toggleShoppingCart}
          />
          <RaShoppingCart
            showCartDetails={showCartDetails}
            openShoppingCart={openShoppingCart}
            closeShoppingCart={closeShoppingCart}
          />
        </div>
      )
    }

    return (
      <ErrorBoundary>
        <div
          className={styles['ra-button-travelplan']}
          onMouseOver={openShoppingCart}
          onMouseLeave={closeShoppingCart}>
          <RaButton
            small
            variant={hasNavbar ? 'primary' : 'secondary-hero'}
            link={{ title: label, url: travelplanUrl }}
            label={label}
            startIcon={suitcaseIcon}
            travelplan
            onFocus={openShoppingCart}
            onBlur={closeShoppingCart}
          />
          <RaShoppingCart
            showCartDetails={showCartDetails}
            openShoppingCart={openShoppingCart}
            closeShoppingCart={closeShoppingCart}
          />
        </div>
      </ErrorBoundary>
    )
  }
)

RaButtonTravelplan.displayName = 'RaButtonTravelplan'

import { useMemo } from 'react'
import { RaTypography } from '@components/core/index.core'
import clsx from 'clsx'
import Link from 'next/link'

import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'
import { AcfLinkItemProps } from '@typings'

import styles from './ra-link.module.scss'

interface RaLinkProps extends AcfLinkItemProps {
  rel?: string
  prefetch?: boolean
  textColor?: 'primary' | 'light' | 'dark'
  weight?: 'regular' | 'semibold' | 'bold'
  removeUnderline?: boolean
}

export const RaLink = ({
  url,
  title,
  target,
  rel = 'noopener, noreferrer',
  prefetch = true,
  textColor = 'primary',
  weight = 'regular',
  removeUnderline = false,
}: RaLinkProps) => {
  const classes = useMemo(() => {
    return clsx(
      styles['ra-link'],
      removeUnderline && styles['ra-link--remove-underline']
    )
  }, [removeUnderline])

  if (!url) return <span className={classes}>{title}</span>

  if (target === '_blank') {
    return (
      <a
        href={stripFrontendUrl(url)}
        target="_blank"
        className={classes}
        rel={rel}
        tabIndex={0}>
        <RaTypography
          component="span"
          weight={weight}
          color={textColor}>
          {title}
        </RaTypography>
      </a>
    )
  }

  return (
    <Link
      prefetch={prefetch}
      href={stripFrontendUrl(url)}
      tabIndex={0}
      className={classes}>
      <RaTypography
        component="span"
        weight={weight}
        color={textColor}>
        {title}
      </RaTypography>
    </Link>
  )
}

RaLink.displayName = 'RaLink'

'use client'
import { useEffect } from 'react'

import { useCart } from '@contexts/cart.context'
import { ga4GetContentGroup } from '@utilities/ga4-helpers/ga4-get-content-group.helpers'
import { GA4Events } from '@utilities/ga4-helpers/GA4Events.helpers'

export const RaGoogleAnalytics = ({ destination, page, referer, GTM_ID }) => {
  const { cartItems } = useCart()
  const analytics = new GA4Events({ mode: 'client', gtmId: GTM_ID })
  useEffect(() => {
    analytics.viewPage(destination, page, referer)

    switch (page.type) {
      case 'post':
        analytics.viewBlog(destination, page, referer)
        break

      case 'rundreise':
        analytics.viewRoundtrip(destination, page, referer)
        break

      case 'reise-baustein':
        analytics.viewBuildingBlock(destination, page, referer)
        break
    }
  }, [destination, page])

  useEffect(() => {
    const contentGroup = ga4GetContentGroup()
    if (contentGroup === 'My Travelplan') {
      analytics.viewCart(cartItems, referer)
    }
  }, [cartItems])

  return null
}

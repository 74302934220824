import { ga4getDepartureDate } from './ga4-get-departure-date.helpers'

export const ga4MakeBookingFormDatalayer = travelplan => {
  if (!travelplan) return {}
  const {
    getincontactoptiontype,
    nrofpax,
    nrofchildren,
    nrofbabies,
    flightyesno,
    extrainformationflights,
    startdate,
  } = travelplan

  const gender = travelplan.paxs?.[0]?.sextype || 'unknown'
  const contactMoment = getincontactoptiontype
  const adults = parseInt(nrofpax) || 1
  const children = parseInt(nrofchildren) || 0
  const babies = parseInt(nrofbabies) || 0
  const numberOfPeople =
    (!nrofpax ? 0 : parseInt(nrofpax)) +
    (!nrofchildren ? 0 : parseInt(nrofchildren)) +
    (!nrofbabies ? 0 : parseInt(nrofbabies))
  const partyComposition = !adults
    ? null
    : `${adults || 0}a, ${children || 0}c, ${babies || 0}b`
  const flightBooked = flightyesno
  const flightProposal = extrainformationflights
  const { fullDepartureDate, departureMonth, departureYear, daysToDeparture } =
    ga4getDepartureDate(startdate)

  return {
    gender: gender,
    contactMoment: contactMoment,
    numberOfPeople:
      !numberOfPeople || numberOfPeople === 0 ? null : numberOfPeople,
    partyComposition,
    adults,
    children,
    babies,
    departureDate: fullDepartureDate,
    departureYear,
    departureMonth,
    daysToDeparture,
    tripDurationFlexibility: null, // Unknown
    tripDuration: null, // Unknown
    flightBooked,
    flightProposal,
  }
}
